import dayjs from "dayjs";

import { openPopup } from "@/actions/popup";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Icon from "@/design-system/Icon";
import useMediaQuery from "@/design-system/useMediaQuery";

import FlexContainer from "@/design-system-v2/FlexContainer";

import { useAppDispatch } from "@/hooks/hooks";

import Typography from "@/design-system-supernova/Typography";

import { theme } from "@/theme";

const BannerNew = ({ isRow }: { isRow: boolean }) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);
  return (
    <UnstyledButton
      onClick={() => dispatch(openPopup("RequestCallBackPopup"))}
      style={{ width: "100%" }}
    >
      <Box
        position="relative"
        background={"linear-gradient(90deg, #7860FC 0%, #21B7DE 50%, #7FEB8C 100%)"}
        zIndex={1201}
        p={{ _: "16px", md: "14px" }}
      >
        <FlexContainer
          alignItems={isMobile ? "start" : "center"}
          justifyContent={isMobile ? "start" : "center"}
          flexDirection={isMobile ? "column" : "row"}
          gap={theme.spacing(1)}
        >
          <Typography
            weightVarient="regular"
            varient="bodyl"
            color={theme.colors.base.white}
            textAlign={isMobile ? "left" : "center"}
            display="inline"
          >
            {isRow
              ? `⚡ Hurry! Exclusive scholarships available - Enroll before 20th Sept ⚡️`
              : `⚡ Hurry! Last Chance for Scholarship - Enroll before Sept. 15 ⚡️`}
          </Typography>
          <FlexContainer>
            <FlexContainer
              alignItems="center"
              gap={theme.spacing(1)}
              ml={isMobile ? theme.spacing(1) : theme.spacing(2)}
              display={isMobile ? "flex" : "inline-flex"}
            >
              <Typography
                weightVarient="semibold"
                varient="bodyl"
                color={isMobile ? theme.colors.purple[600] : "#344054"}
                as={"span"}
                style={{
                  textDecoration: isMobile ? "underline" : "none",
                }}
              >
                Apply Now
              </Typography>
              {!isMobile && (
                <Icon type="arrow-up-right" color={theme.colors.gray[700]} varient="line" />
              )}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Box>
    </UnstyledButton>
  );
};

export default BannerNew;
