import * as ReactPhoneInput2 from "react-phone-input-2";

import Box from "@/design-system/Box";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

import { PhoneInputCustomCSS } from "./phoneinput.style";

const PhoneInput = ({
  value,
  onChange,
  error,
  isIndia = true,
  errorName,
  onBlur,
  fixedHeight,
  onFocus,
  id,
  name,
}: any) => {
  return (
    <Box mb={theme.spacing(4)}>
      <div>
        <PhoneInputCustomCSS fixedHeight={fixedHeight}>
          <ReactPhoneInput2.default
            country={isIndia ? "in" : "us"}
            value={value}
            onChange={onChange}
            countryCodeEditable={false}
            enableSearch={true}
            onBlur={onBlur}
            inputProps={{
              id: id,
              name: name,
              onFocus: onFocus,
            }}
          />
        </PhoneInputCustomCSS>
      </div>

      {error && (
        <Typography mt={"10px"} varient="captionl" color={"error.500"} as="p">
          {errorName}
        </Typography>
      )}
    </Box>
  );
};
export default PhoneInput;
