import { motion } from "framer-motion";
import styled from "styled-components";

import Box from "@/design-system/Box";
import { StyledContainer } from "@/design-system/Container/container.styles";
import GridContainer from "@/design-system/GridContainer";

import { theme } from "@/theme";

import Courses from "./Courses/Courses";
import Partners from "./Partners/Partners";
import Resources from "./Resources/Resources";

const MegaMenuStyle = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  height: auto;
  z-index: -1;
  max-width: 1520px;
  margin: auto;
  border-radius: 16px;
  background-color: ${theme.colors.base.white};
  box-shadow: 0px 4px 51.6px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid ${theme.colors.gray[200]};
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

interface MegaMenuInterface {
  currentNavigation?: string;
  isRow?: boolean;
}

const MegaMenu = ({ currentNavigation, isRow }: MegaMenuInterface) => {
  return (
    <StyledContainer>
      <MegaMenuStyle
        initial="collapsed"
        animate="open"
        exit="collapsed"
        style={{ overflow: "hidden" }}
        variants={{
          open: { top: "0px", opacity: 1 },
          collapsed: { top: "30px", opacity: 0 },
        }}
        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
      >
        <Box
          display={"grid"}
          gridTemplateColumns="repeat(3, 100%)"
          style={{
            transition: "all 0.9s cubic-bezier(0.71, -0.02, 0.06, 1.15) 0s",
            transform: `translateX(${
              currentNavigation === "courses"
                ? "0%"
                : currentNavigation === "resources"
                  ? "-100%"
                  : currentNavigation === "partners"
                    ? "-200%"
                    : "0"
            })`,
          }}
        >
          <GridContainer spacing={theme.spacing(8)}>
            <Courses isRow={isRow} />
          </GridContainer>
          <GridContainer spacing={theme.spacing(8)}>
            <Resources />
          </GridContainer>
          <GridContainer spacing={theme.spacing(8)}>
            <Partners />
          </GridContainer>
        </Box>
      </MegaMenuStyle>
    </StyledContainer>
  );
};

export default MegaMenu;
