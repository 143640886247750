import Link from "next/link";
import styled from "styled-components";

import Icon from "@/design-system/Icon";

import Typography from "@/design-system-v3/Typography";

import { theme } from "@/theme";

const CoursesBoxStyle = styled.div`
  padding: 24px;
  margin-top: 24px;
  background-color: inherit;
`;

const CourseLinkStyle = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CoursesLink = ({ setIsNavMobileOpen, isRow }: any) => {
  return (
    <CoursesBoxStyle>
      <Typography
        style={{
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "16px",
          paddingLeft: "8px",
          paddingBottom: "8px",
        }}
        color={theme.colors.purple[500]}
      >
        {isRow ? "PROGRAMS" : "COURSES"}
      </Typography>

      <CourseLinkStyle>
        <Link
          href={"/courses/building-information-modelling"}
          onClick={() => setIsNavMobileOpen(false)}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            {isRow
              ? "PGP in Building Information Modelling (BIM) for Architects"
              : "BIM for Architects"}
          </Typography>
          <Icon size={16} type="chevron-right" color={theme.colors.base.black} varient="line" />
        </Link>
      </CourseLinkStyle>
      <CourseLinkStyle>
        <Link
          href={"/courses/computational-design"}
          onClick={() => setIsNavMobileOpen(false)}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            {isRow ? "PGP in Parametric Design for Architects" : "Master Computational Design"}
          </Typography>
          <Icon size={16} type="chevron-right" color={theme.colors.base.black} varient="line" />
        </Link>
      </CourseLinkStyle>
      <CourseLinkStyle>
        <Link
          href={"/courses/bim-for-civil-engineers"}
          onClick={() => setIsNavMobileOpen(false)}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            {isRow
              ? "PGP in Building Information Modelling (BIM) for Civil Engineers"
              : "BIM for Civil engineers"}
          </Typography>
          <Icon size={16} type="chevron-right" color={theme.colors.base.black} varient="line" />
        </Link>

        {/* <Badge
          text={"Coming Soon"}
          badgeStyle={"light"}
          colorVarient={"warning"}
          dotLeading={true}
          size={"md"}
        /> */}
      </CourseLinkStyle>
    </CoursesBoxStyle>
  );
};

export default CoursesLink;
